
import {computed, defineComponent} from "vue";
import store from "@/store";
import RouterNavigate from "@/components/base/common/RouterNavigate.vue";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "LibraryLayout",
  components: {InfoBox, RouterNavigate},
  setup() {
    const menuData = computed(() => store.state.DocModule.menuData)
    return {
      menuData,
    }
  },
  methods: {
    buildNavigate(items) {
      return items.map(item => Object.assign({}, {name: item.name, uri: '/library/' + item.id}))
    }
  }
})
