import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "py-5" }
const _hoisted_2 = { class: "card rounded border p-3" }
const _hoisted_3 = { class: "d-grid" }
const _hoisted_4 = { class: "nav nav-tabs border-0 flex-nowrap text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nav-item",
              key: index
            }, [
              _createVNode(_component_router_link, {
                to: `/`+_ctx.spaceId+item.uri,
                class: "nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}