
import {defineComponent, PropType} from "vue";
import {GetSpaceId} from "@/core/composite/composite";

export default defineComponent({
  name: "RouterNavigate",
  props: {
    items: {
      type: Array as PropType<any>,
      required: true,
    }
  },
  setup() {
    return {
      ...GetSpaceId(),
    }
  }
})
